import React from 'react';
import {
Nav,
NavLink,
NavMenu,
// NavBtn,
// NavBtnLink,
} from './NavbarElements';

const Navbar = () => {
return (
	<>
	<Nav>
		<NavMenu>
		<NavLink to='/' activeStyle>
			Home
		</NavLink>
		<NavLink to='/about' activeStyle>
			About
		</NavLink>
		<NavLink to='/projects' activeStyle>
			Projects
		</NavLink>
		<NavLink to='/resume' activeStyle>
			Resume
		</NavLink>
		<NavLink to='/blog' activeStyle>
            Blog
		</NavLink>
		</NavMenu>
	</Nav>
	</>
);
};

export default Navbar;
