import React from 'react';
import Typist from 'react-typist';
import "../styles/Intro.css"
import FadeInSection from './FadeInSection';
import jeff_jpg from '../images/jeff_jpg.jpg';
import dogphone from '../images/dogphone.jpg';
import spoon_wow from '../images/spoon_wow.jpg';
import vancouver from '../images/vancouver.jpg';
import 'animate.css';
import { SocialIcon } from 'react-social-icons';

const IconList = () => {
    return (
        <>
            {/* <ul className="App"> */}
            <ul>
                <li>
                    <SocialIcon url="https://www.github.com/jleekwong" target="_blank" fgColor='black' bgColor='pink'/>
                </li>
                <li>
                    <SocialIcon url="https://www.linkedin.com/in/jleekwong/"  target="_blank"/>
                </li>
            </ul>
        </>
    );
}

class Intro extends React.Component {
    render() {
        return (
            <div id="intro">
                <img 
                    src={RandomImage()}
                    class="animate__animated animate__fadeIn"
                    alt="logo"/>
                <Typist avgTypingDelay={80}>
                    <span className="intro-title">
                        {"Hi there! I'm "}
                        <span className="intro-name">{"Jeff 👋🏻"}</span>
                    </span>
                </Typist>
                <FadeInSection></FadeInSection>
                <br></br>
                <br></br>
                <IconList></IconList>
            </div>
        );
    }
}

function RandomImage() {
    var image_routes = [
        jeff_jpg,
        dogphone,
        spoon_wow,
        vancouver
    ];

    var num = Math.floor(Math.random() * image_routes.length);
    return image_routes[num];
}

export default Intro;