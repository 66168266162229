import './App.css';
import React from 'react';
import Navbar from './components/NavBar';
import About from './pages/about'
import Home from './pages/home'
import Projects from './pages/projects'
import Resume from './pages/resume'
import Blog from './pages/blog'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// TODO: Will be implemented in future PR
// import background1 from './images/purple-sky-1.jpg';
// import background2 from './images/purple-sky-2.jpg';
// import background3 from './images/purple-sky-3.jpg';
// import background4 from './images/purple-sky-4.jpg';

function ShowContent() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/projects" exact element={<Projects />} />
        <Route path="/resume" exact element={<Resume />} />
        <Route path="/blog" exact element={<Blog />} />
      </Routes>
    </Router>
  );
}

function App() {
  return (
    // TODO: Get background image
    // <div className="App" 
    //   style={{
    //     backgroundImage: `url(${background4})` ,
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center center',
    //     backgroundRepeat: 'no-repeat',
    //   }}>
    <div className="App">
      <ShowContent></ShowContent>
    </div>
  );
}

export default App;
