import React from 'react';

const Background = () => {
	return (
		<>
			<body>
				<h1>Background</h1>
				<p>Hi! I'm a software engineer at Microsoft working on the Azure team in Redmond WA. Prior to Microsoft, I worked on building tools at Intel Corporation. </p>
				<h1>Education</h1>
				<p><b>B.S. Electrical Engineering</b> with minor in Computer Science, University of Nevada, Reno</p>
				<h1>About Me</h1>
				<p>When I'm not reversing a linked list, you can find me on the tennis courts trying (praying) to make my first serves in! I also enjoy hiking, skateboarding, drinking boba, and smothering my cat Mochi with love. Playing video games has always been a hobby of mine and one of my recent favorites has been Inscryption on Steam. </p>
			</body>
		</>
	);
}

const About = () => {
	return (
	<div class="animate__animated animate__fadeIn">
		<Background></Background>
	</div>
	);
};

export default About;
