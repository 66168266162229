import React from 'react';

const Blog = () => {
  return (
    <body>
        <h1>Under construction, check again soon!</h1>
    </body>
  );
};

export default Blog;
