import React from 'react';

const Resume = () => {
  return (
    <body>
        <h1>Under construction, check again soon!</h1>
    </body>
  );
};

export default Resume;
